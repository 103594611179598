<template>
<section class="promotion">
  <div class="row">
      <div class="col-12 col-lg-7 head">
        <div class="container wrap-title">
          <TitleHead title="Promotions" />
        </div>

        <div v-show="rawPromotions.length > 0" class="container wrap-promotion">
          <div
            v-for="(promotion) in rawPromotions.slice(0, 4)"
            :key="promotion.id"
            class="box-promotion"
          >
            <img :src="promotion.image" :alt="promotion.title" class="icon-promotion">
            <div class="content">
              <h3>{{ promotion.title }}</h3>
              <div v-html="promotion.description" class="description" />
            </div>
          </div>

          <div class="wrap-condition-content">
            <p>Terms and Conditions apply</p>
          </div>

          <div class="wrap-route">
            <div class="route">
              <router-link
                id="homepagePromotionLink"
                :to="{ name: 'promotions' }">
                <span>SEE MORE DETAILS</span>
                <div class="line"></div>
              </router-link>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-lg-5">
        <div class="wrap-graphic">
          <div ref="promotionGraphic" v-if="showGraphic" class="wrap-icon">
            <img src="@/assets/graphics/promotion-back.svg" alt="Promotion truecoffee go graphics component" class="back">
            <div
              v-anime="{
                targets: '.spring-physics-demo .el',
                keyframes: [
                  { scale: 0 },
                  { scale: 1 },
                ],
                duration: 2000,
                delay: 100,
                endDelay: 1000,
                direction: 'alternate'
              }"
              class="cicle"
              style="transform: scale(0);"
            >
              <img src="@/assets/graphics/promotion-cicle.svg" alt="Promotion truecoffee go graphics component" class="front">
              <div class="central">
                <img src="@/assets/graphics/promotion-heart.svg" alt="Promotion truecoffee go graphics component" class="heart">
                <!-- <p class="number">2000</p> -->
                <number
                  :from="1983"
                  :to="1958"
                  :duration="2"
                  :delay="2"
                  easing="Power1.easeOut"
                  class="number"
                />
              </div>
            </div>

            <div class="wrap-heart">
              <img
                v-anime="{
                  targets: '.spring-physics-demo .el',
                  keyframes: [
                    { scale: 0 },
                    { scale: 1 },
                  ],
                  duration: 1500,
                  delay: 4600
                }"
                src="@/assets/graphics/promotion-heart-plus.svg"
                alt="Promotion truecoffee go graphics component"
                class="heart-plus one"
                style="transform: scale(0);"
              >
              <img
                v-anime="{
                  targets: '.spring-physics-demo .el',
                  keyframes: [
                    { scale: 0 },
                    { scale: 1 },
                  ],
                  duration: 1500,
                  delay: 4400
                }"
                src="@/assets/graphics/promotion-heart-plus.svg"
                alt="Promotion truecoffee go graphics component"
                class="heart-plus two"
                style="transform: scale(0);"
              >
              <img
                v-anime="{
                  targets: '.spring-physics-demo .el',
                  keyframes: [
                    { scale: 0 },
                    { scale: 1 },
                  ],
                  duration: 1500,
                  delay: 4200
                }"
                src="@/assets/graphics/promotion-heart-plus.svg"
                alt="Promotion truecoffee go graphics component"
                class="heart-plus three"
                style="transform: scale(0);"
              >
            </div>
          </div>
        </div>
      </div>
    </div>
</section>
</template>

<script>
import { mapState } from 'vuex'

const TitleHead = () => import('@/components/TitleHead')

export default {
  components: {
    TitleHead
  },

  computed: {
    ...mapState({
      rawPromotions: state => state.promotion.rawPromotions
    })
  },

  data () {
    return {
      showGraphic: false,
      linkDownload: ''
    }
  },

  created () {
    this.$store.dispatch('promotion/getPromotion')

    this.checkDevice()
  },

  mounted () {
    window.addEventListener('scroll', () => {
      if (window.innerWidth <= 768 && window.pageYOffset >= 3150) {
        this.showGraphic = true
      }

      if (window.innerWidth >= 768 && window.pageYOffset >= 2236) {
        this.showGraphic = true
      }
    })
  },

  methods: {
    checkDevice () {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera

      if (/android/i.test(userAgent)) {
        this.linkDownload = 'https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=th.co.truecorp.truecoffee.go&hl=en_US'
      } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        this.linkDownload = 'itms-apps://itunes.apple.com/th/app/truecoffee-go/id1483000620'
      } else {
        this.linkDownload = '/application'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/variable.scss';

.promotion {
  overflow: hidden;
  padding-top: 60px;
  width: 100%;

  .row {
    @media screen and (min-width: 1024px) {
      display: flex;
      flex-direction: row-reverse;
    }
  }

  .head {
    display: flex;
    flex-direction: column;

    // @media screen and (min-width: 1024px) {
    //   overflow: scroll;
    //   justify-content: flex-start;
    //   padding-left: 70px;
    //   padding-right: 120px;
    //   max-height: calc(100vh - 60px);
    // }

    .wrap-title {
      display: flex;
      justify-content: center;

      @media screen and (min-width: 1024px) {
        display: block;
      }
    }

    .wrap-promotion {
      .box-promotion {
        display: flex;
        align-items: center;
        padding-top: 38px;
        padding-bottom: 38px;
        border-top: 1px solid #ccc;

        .icon-promotion {
          margin: 0 25px;
          width: 32px;
          height: 100%;
        }

        .content {
          width: 100%;

          h3 {
            width: fit-content;
            font-family: 'DB Ozone X Bd';
            font-size: 18px;
            color: black;
          }

          .description {
            /deep/ p {
              width: 100%;
              margin-top: 5px;
              white-space: pre-line;
              text-transform: none;
              font-size: 18px;
              color: black;

              .link-download {
                color: $color-main;
                font-weight: 700;
              }
            }

            /deep/ ul {
              margin-top: 6px;
              margin-bottom: 0;
              padding-left: 0;

              li::before {
                content: '-';
              }

              li {
                list-style-type: none;
                font-family: 'DB Ozone X', sans-serif;
                white-space: pre-line;
                text-transform: none;
                font-size: 18px;
                color: black;
              }
            }
          }
        }
      }

      .box-promotion:first-child {
        padding-top: 10px;
        border: none;
      }

      .wrap-condition-content {
        margin-bottom: 38px;

        p {
          opacity: 0.6;
          text-align: right;
          font-size: 14px;
        }
      }

      .wrap-route {
        display: flex;
        justify-content: center;
        margin-top: -28px;
      }

      .route {
        margin-top: 20px;
        margin-bottom: 47px;
        width: fit-content;

        @media screen and (min-width: 1024px) {
          margin-top: 32px;
          margin-bottom: 56px;
        }

        span {
          font-family: 'DB Ozone X Bd';
          font-size: 18px;
        }

        .line {
          margin-top: -6px;
          width: 50%;
          height: 2px;
          background-color: $color-main;
          transition: 800ms $ease;
        }
      }

      .route:hover {
        .line {
          width: 100%;
        }
      }
    }
  }

  .wrap-graphic {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vw;
    background-color: #B20014;

    @media screen and (min-width: 1024px) {
      width: 100%;
      height: 100%;
    }

    .wrap-icon {
      position: relative;
      width: 70%;
      height: 80%;

      .back {
        transform: translate(-50%, -50%);
        position: absolute;
        margin-top: 50%;
        margin-left: 50%;
        width: 80%;
        height: auto;
      }

      .cicle {
        position: absolute;
        top: 0;
        right: 0;
        width: 32%;
        padding-bottom: 32%;

        .front {
          position: absolute;
        }

        .central {
          position: absolute;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;

          // @media screen and (min-width: 768px) {
          //   height: 85%;
          // }

          // @media screen and (min-width: 1024px) {
          //   height: 100%;
          // }

          .heart {
            margin-top: 6%;
            width: 35%;
            height: 35%;

            @media screen and (min-width: 1024px) {
              width: 30%;
              height: auto;
            }
          }

          .number {
            font-family: 'DB Ozone X', sans-serif;
            font-size: 126%;
            font-weight: 700;
            color: #EC1C24;

            @media screen and (min-width: 1280px) {
              font-size: 166%;
            }
          }
        }
      }

      .wrap-heart {
        position: absolute;
        top: 0;
        left: 80%;
        width: 100%;

        .one {
          width: 16%;
          height: auto;
        }

        .two {
          margin-top: 18%;
          margin-left: -2%;
          width: 14%;
          height: auto;
        }

        .three {
          margin-top: -16%;
          margin-left: -8%;
          width: 12%;
          height: auto;
        }
      }
    }
  }
}
</style>
